import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper">
            <img src="page_not_found.png" className='img-fluid'/>
        </div >
    )
}

export default PageNotFound;